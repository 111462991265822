import { relations } from 'drizzle-orm';
import { boolean, integer, pgTable, primaryKey, serial, text, timestamp, uuid } from 'drizzle-orm/pg-core';
import { User, users } from './schema';
import { z } from 'zod';

export const ChatsTable = pgTable('chats', {
    id: uuid('id').defaultRandom().primaryKey(),
    createdAt: timestamp('created_at', { withTimezone: true, mode: 'string' }).defaultNow(),
    updatedAt: timestamp('updated_at', { withTimezone: true, mode: 'string' }).defaultNow(),
    isFree: boolean('is_free').default(false),
    lastMessageAt: timestamp('last_message_at', { withTimezone: true, mode: 'string' }).defaultNow(),
    lastMessage: text('last_message')
});

export const SystemMessagesTable = pgTable('system_messages', {
    id: uuid('id').defaultRandom().primaryKey(),
    chatId: uuid('chatId')
        .references(() => ChatsTable.id)
        .notNull(),
    text: text('text').notNull(),
    createdAt: timestamp('created_at', { withTimezone: true, mode: 'string' }).defaultNow().notNull()
});

export const MessagesTable = pgTable('messages', {
    id: uuid('id').defaultRandom().primaryKey(),
    pbChatId: text('pbChatId'),
    chatId: uuid('chatId')
        .references(() => ChatsTable.id)
        .notNull(),
    userId: text('userId')
        .references(() => users.id)
        .notNull(),
    text: text('text').notNull(),
    assetPath: text('assetPath'),
    blurAssetPath: text('blurAssetPath'),
    createdAt: timestamp('createdAt', { withTimezone: true, mode: 'string' }).defaultNow().notNull(),
    readAt: timestamp('readAt', { withTimezone: true, mode: 'string' }),
    readBy: text('readBy').array(),
    isProcessing: boolean('isProcessing').default(false),
    isUnlocked: boolean('isUnlocked').default(false),
    unlockPrice: integer('unlockPrice').default(0).notNull()
});

export const UserChatsTable = pgTable(
    'user_chats',
    {
        createdAt: timestamp('created_at', { withTimezone: true, mode: 'string' }).defaultNow(),
        updatedAt: timestamp('updated_at', { withTimezone: true, mode: 'string' }).defaultNow().notNull(),
        userId: text('user_id')
            .references(() => users.id)
            .notNull(),
        chatId: uuid('chat_id')
            .references(() => ChatsTable.id)
            .notNull(),
        pbChatId: text('pbChatId'),
        isPinned: boolean('isPinned').default(false),
        isRead: boolean('isRead').default(false)
    },
    table => {
        return {
            pk: primaryKey({ columns: [table.userId, table.chatId] })
        };
    }
);

export const messagesRelations = relations(MessagesTable, ({ one }) => ({
    chat: one(ChatsTable, {
        fields: [MessagesTable.chatId],
        references: [ChatsTable.id]
    }),
    user: one(users, {
        fields: [MessagesTable.userId],
        references: [users.id]
    })
}));

export const userChatsRelations = relations(UserChatsTable, ({ one }) => ({
    chat: one(ChatsTable, {
        fields: [UserChatsTable.chatId],
        references: [ChatsTable.id]
    }),
    user: one(users, {
        fields: [UserChatsTable.userId],
        references: [users.id]
    })
}));

export const chatsRelations = relations(ChatsTable, ({ many }) => ({
    membersChats: many(UserChatsTable),
    messages: many(MessagesTable)
}));

export type UserChat = typeof UserChatsTable.$inferSelect;
export type UserChatRich = UserChat & { user: User; chat: Chat };
export type Message = typeof MessagesTable.$inferSelect;
export type MessageInsert = typeof MessagesTable.$inferInsert;
export type RichMessage = Message & { user: User; chat: Chat };
export type Chat = typeof ChatsTable.$inferSelect;
export type ChatRich = Chat & { membersChats: UserChatRich[]; messages: Message[] };

export const zodMessageSchema = z.object({
    text: z.string({ message: 'Toto pole je povinné' }).min(1, 'Toto pole je povinné.').max(500, 'Nesmí přesahovat délku 500 znaků.'),
    assetPath: z.string().optional().nullable(),
    blurAssetPath: z.string().optional().nullable()
});
