import { relations } from 'drizzle-orm';
import { boolean, integer, json, jsonb, pgEnum, pgTable, serial, text, timestamp, unique, uuid, varchar } from 'drizzle-orm/pg-core';
import { coinsTopup } from './adminSchema';
import { UserChatsTable } from './chat.schema';
import { NotificationsTable } from './notification.schema';

export const roleEnum = pgEnum('role', ['admin', 'user', 'creator']);
export const creatorCatEnum = pgEnum('category', ['glam', 'topless', 'adult']);
export const contentTypesEnum = pgEnum('contentTypes', ['image', 'video']);
export const notificationTypeEnum = pgEnum('notificationType', ['info', 'tip', 'sub', 'colab']);
export const transactionTypeEnum = pgEnum('transactionType', ['content', 'subscription', 'message', 'messageAsset']);
export const contentStatusEnum = pgEnum('contentStatus', ['processing', 'finished', 'failed']);
export const currencyEnum = pgEnum('currency', ['czk', 'usd', 'eur']);

export const sessionTable = pgTable('session', {
    id: text('id').primaryKey(),
    userId: text('user_id')
        .notNull()
        .references(() => users.id, { onDelete: 'cascade' }),
    expiresAt: timestamp('expiresAt', {
        withTimezone: true,
        mode: 'date'
    }).notNull()
});

export const users = pgTable('users', {
    providerId: text('providerId').unique(),
    id: text('id').primaryKey(),
    inviteCode: text('inviteCode').default('').notNull(),
    promoCode: text('promoCode').default('').notNull(),
    role: roleEnum('role').array().notNull().default(['user']),
    username: varchar('username').unique().notNull(),
    email: varchar('email', { length: 255 }).unique().notNull(),
    hashPassword: varchar('password_hash', { length: 255 }).default('').notNull(),
    firstName: varchar('first_name', { length: 255 }).notNull().default('').notNull(),
    lastName: varchar('last_name', { length: 255 }).notNull().default('').notNull(),
    fullName: varchar('full_name', { length: 255 }).notNull().default('').notNull(),
    createdAt: timestamp('created_at', { mode: 'string' }).defaultNow().notNull(),
    image: varchar('image').default(''),
    coins: integer('coins').default(0).notNull(),
    verifiedCreator: boolean('verifiedCreator').default(false).notNull(),
    price: integer('price').notNull().default(0),
    messagePrice: integer('messagePrice').notNull().default(0),
    recievePicPrice: integer('recievePicPrice').notNull().default(1000),
    bio: text('bio').default('').notNull(),
    phoneNumber: varchar('phoneNumber').default('').notNull(),
    city: varchar('city').default('').notNull(),
    porfolio: text('porfolio').default('').notNull(),
    age: integer('age').default(18).notNull(),
    bday: varchar('bday', { length: 10 }).default(''),
    pushNotificationsEnabled: boolean('pushNotificationsEnabled').default(false).notNull(),
    pushNotificationsData: json('pushNotificationsData').default({}),
    adminNote: text('adminNote').default('').notNull(),
    creatorCategory: creatorCatEnum('creatorCategory'),
    connectedAccounts: text('connectedAccounts').array(),
    verifedEmail: boolean('verifedEmail').default(false).notNull(),
    activeAccount: boolean('activeAccount').default(true).notNull(),
    creatorRank: integer('creatorRank').default(0).notNull(),
    onboardingStep: integer('onboardingStep').default(0),
    onboardingCompleted: boolean('onboardingCompleted').default(false).notNull(),
    monthlyGoal: integer('monthlyGoal').default(0).notNull()
});

export const userRelations = relations(users, ({ many, one }) => ({
    creatorContent: many(creatorContent),
    userSubsription: many(subscriptions, {
        relationName: 'userSubsription'
    }),
    creatorSubscription: many(subscriptions, { relationName: 'creatorSubscription' }),
    purchasedContent: many(PurchasedContentTable),
    coinsTopup: many(coinsTopup),
    userChats: many(UserChatsTable),
    notifications_forUser: many(NotificationsTable, {
        relationName: 'notifications_forUser_users_id'
    }),
    notifications_fromUser: many(NotificationsTable, {
        relationName: 'notifications_fromUser_users_id'
    }),
    sessions: many(sessionTable),
    onlineStatus: one(OnlineUsersTable)
}));

export const OnlineUsersTable = pgTable('online_users', {
    userId: text('userId')
        .primaryKey()
        .notNull()
        .references(() => users.id, { onDelete: 'cascade' }),
    lastSeen: timestamp('last_Seen', { withTimezone: true, mode: 'string' }).defaultNow().notNull(),
    isOnline: boolean('is_online').default(false).notNull()
});

export const OnlineUsersRelations = relations(OnlineUsersTable, ({ one }) => ({
    user: one(users, {
        fields: [OnlineUsersTable.userId],
        references: [users.id]
    })
}));

export const VerificationTokensTable = pgTable('verificationTokens', {
    token: text('token').notNull().primaryKey(),
    userId: text('userId')
        .notNull()
        .references(() => users.id, { onDelete: 'cascade' }),
    expiresAt: timestamp('expiresAt').defaultNow().notNull(),
    createdAt: timestamp('createdAt').defaultNow().notNull()
});

export const subscriptions = pgTable('subscriptions', {
    id: uuid('id').defaultRandom().primaryKey(),
    userId: text('userId')
        .notNull()
        .references(() => users.id, { onDelete: 'cascade' }),
    creatorId: text('creatorId')
        .notNull()
        .references(() => users.id, { onDelete: 'cascade' }),
    subscribedDate: timestamp('subscribedDate', { mode: 'string' }).notNull().defaultNow(),
    expiresAt: timestamp('expiresAt', { mode: 'string' }).notNull().defaultNow(),
    updatedAt: timestamp('updatedAt', { mode: 'string' }).notNull().defaultNow(),
    renew: boolean('renew').default(true).notNull()
});

export const subscriptionRelations = relations(subscriptions, ({ one }) => ({
    creator: one(users, {
        fields: [subscriptions.creatorId],
        references: [users.id],
        relationName: 'creatorSubscription'
    }),
    user: one(users, {
        fields: [subscriptions.userId],
        references: [users.id],
        relationName: 'userSubsription'
    })
}));

export const TransactionsTable = pgTable('transactions', {
    id: uuid('id').defaultRandom().primaryKey(),
    buyerId: text('buyerId')
        .notNull()
        .references(() => users.id, { onDelete: 'no action' }),
    sellerId: text('sellerId')
        .notNull()
        .references(() => users.id, { onDelete: 'no action' }),
    contentId: uuid('contentId').references(() => creatorContent.id, { onDelete: 'no action' }),
    messageId: varchar('messageId'),
    type: transactionTypeEnum('type').notNull().default('subscription'),
    amountInCoins: integer('amountInCoins').notNull(),
    amountInCZK: integer('amountInCZK').notNull(),
    createdAt: timestamp('createdAt').defaultNow().notNull()
});

export const TransactionsRelations = relations(TransactionsTable, ({ one }) => ({
    buyer: one(users, {
        fields: [TransactionsTable.buyerId],
        references: [users.id]
    }),
    seller: one(users, {
        fields: [TransactionsTable.sellerId],
        references: [users.id]
    }),
    content: one(creatorContent, {
        fields: [TransactionsTable.contentId],
        references: [creatorContent.id]
    })
}));

export const PurchasedContentTable = pgTable('purchasedContent', {
    id: uuid('id').defaultRandom().primaryKey(),
    userId: text('userId')
        .notNull()
        .references(() => users.id, { onDelete: 'cascade' }),
    creatorId: text('creatorId')
        .notNull()
        .references(() => users.id, { onDelete: 'cascade' }),
    contentId: uuid('contentId')
        .notNull()
        .references(() => creatorContent.id, { onDelete: 'cascade' }),
    purchasedAt: timestamp('purchasedAt').defaultNow().notNull()
});

export const purchasedContentRelations = relations(PurchasedContentTable, ({ one }) => ({
    user: one(users, {
        fields: [PurchasedContentTable.userId],
        references: [users.id]
    }),
    content: one(creatorContent, {
        fields: [PurchasedContentTable.contentId],
        references: [creatorContent.id]
    })
}));

export const UserSettingsTable = pgTable('userSettings', {
    id: uuid('id').defaultRandom().primaryKey().notNull(),
    userId: text('userId')
        .notNull()
        .references(() => users.id, { onDelete: 'cascade' }),
    pushNotifications: boolean('pushNotifications').default(false).notNull(),
    inAppNotifications: boolean('inAppNotifications').default(false).notNull(),
    newMessageNotifications: boolean('newMessageNotifications').default(false).notNull(),
    newContentNotifications: boolean('newContentNotifications').default(false).notNull(),
    topCreatorsNotifications: boolean('topCreatorsNotifications').default(false).notNull(),
    theme: varchar('theme').default('dark').notNull(),
    renewSubscriptions: boolean('renewSubscriptions').default(false).notNull(),
    language: varchar('language').default('en').notNull(),
    currency: currencyEnum('currency').default('czk').notNull()
});

export const promoCodes = pgTable(
    'promoCodes',
    {
        id: uuid('id').defaultRandom().primaryKey().notNull(),
        code: varchar('code').notNull(),
        value: integer('value').default(0).notNull()
    },
    table => {
        return {
            promoCodesCodeUnique: unique('promoCodes_code_unique').on(table.code)
        };
    }
);

export const creatorContent = pgTable('creatorContent', {
    id: uuid('id').defaultRandom().primaryKey(),
    creatorId: text('creatorId')
        .notNull()
        .references(() => users.id, { onDelete: 'cascade' }),
    blurURL: varchar('blurURL').default('').notNull(),
    premiumURL: varchar('premiumURL').default('').notNull(),
    createdAt: timestamp('createdAt').defaultNow(),
    title: varchar('title').default('').notNull(),
    text: text('text').default('').notNull(),
    contentType: contentTypesEnum('contentType').notNull().default('image'),
    exclusive: boolean('exclusive').default(true).notNull(),
    colabWith: varchar('colabWith').references(() => users.username, { onDelete: 'cascade' }),
    exclusivePrice: integer('exclusivePrice').default(0).notNull(),
    thumb: varchar('thumb').default('').notNull(),
    thumbBlur: varchar('thumbBlur').default('').notNull(),
    colabAccepted: boolean('colabAccepted').default(false).notNull(),
    contentStatus: contentStatusEnum('contentStatus').default('processing').notNull(),
    archived: boolean('archived').default(false).notNull(),
    special: varchar('special').default('').notNull()
});

export const creatorContentRelations = relations(creatorContent, ({ one }) => ({
    creator: one(users, {
        fields: [creatorContent.creatorId],
        references: [users.id]
    })
}));

export const backupLogs = pgTable('backup_logs', {
    id: serial('id').primaryKey(),
    startedAt: timestamp('started_at').notNull().defaultNow(),
    finishedAt: timestamp('finished_at'),
    status: text('status').notNull(),
    details: jsonb('details')
});

export type Session = typeof sessionTable.$inferSelect;
export type User = typeof users.$inferSelect;
export type OnlineUser = typeof OnlineUsersTable.$inferSelect;
export type UserWithPurchased = User & { purchasedContent: PurchasedContent[] };
export type SubscriptionInsert = typeof subscriptions.$inferInsert;
export type PurchasedContent = typeof PurchasedContentTable.$inferSelect;
export type PurchasedContentWithContent = PurchasedContent & { content: Content };
type Subscribers = typeof subscriptions.$inferSelect;
export type Content = typeof creatorContent.$inferSelect;
export type ContentWithCreator = Content & { creator: User };
export type CreatorWithContent = User & { creatorContent: Content[] };
export type UserSettings = typeof UserSettingsTable.$inferSelect;
export type Subscription = typeof subscriptions.$inferSelect;
export type Transaction = typeof TransactionsTable.$inferSelect & {
    content?: Content;
    buyer?: User;
    seller?: User;
};
export enum Role {
    Admin = 'admin',
    User = 'user',
    Creator = 'creator'
}
export enum CreatorCategory {
    Glam = 'glam',
    Topless = 'topless',
    Adult = 'adult'
}
