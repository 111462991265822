import * as React from 'react';

import { cn } from '@/lib/utils';
import { Separator } from '@radix-ui/react-select';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
    return (
        <input
            type={type}
            className={cn(
                'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                className
            )}
            ref={ref}
            {...props}
        />
    );
});
Input.displayName = 'Input';

type FancyProps = React.InputHTMLAttributes<HTMLInputElement> & {
    className?: string;
    startAdorment?: React.ReactNode;
    endAdorment?: React.ReactNode;
    label?: string;
    showSeparator?: boolean;
};

const FancyInput = React.forwardRef<HTMLInputElement, FancyProps>(({ className, showSeparator = false, ...props }, ref) => {
    return (
        <div
            className={cn(
                'flex h-10 w-full items-center rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus:outline-blue-300 disabled:cursor-not-allowed disabled:opacity-50',
                className
            )}>
            {props.startAdorment && <div className="mr-2">{props.startAdorment}</div>}
            <label className="text-muted-foreground">{props.label}</label>
            {showSeparator && <div className="mx-2 flex h-full w-px bg-border" />}
            <input className="ml-2 flex-1 border-none bg-transparent focus-visible:outline-none" ref={ref} {...props} />
            {props.endAdorment && <div className="ml-2">{props.endAdorment}</div>}
        </div>
    );
});
FancyInput.displayName = 'FancyInput';
interface InputAdormentProps extends InputProps {
    adornment: React.ReactNode;
}
const InputAdorment = React.forwardRef<HTMLInputElement, InputAdormentProps>(({ className, type, adornment, ...props }, ref) => {
    return (
        <div
            className={cn(
                'flex h-10 w-full items-center rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
                className
            )}>
            <input className="flex-1 border-none bg-transparent focus-visible:outline-none" type={type} ref={ref} {...props} />
            {adornment}
        </div>
    );
});
InputAdorment.displayName = 'InputAdorment';

export { Input, InputAdorment, FancyInput };
