import { boolean, integer, pgTable, primaryKey, serial, text, timestamp, uuid, varchar, unique } from 'drizzle-orm/pg-core';
import { users } from './schema';
import { relations } from 'drizzle-orm';

export const appSettings = pgTable('app_settings', {
    id: serial('id').primaryKey(),
    blurValue: integer('blurValue').default(75).notNull(),
    maintanance: boolean('maintanance').default(false).notNull(),
    showAdventOnFeed: boolean('showAdventOnFeed').default(false).notNull(),
    snow: boolean('snow').default(false).notNull(),
    eurConversion: integer('eurConversion').default(0).notNull(),
    usdConversion: integer('usdConversion').default(0).notNull()
});

export const coinsTopup = pgTable('coins_topup', {
    id: uuid('id').defaultRandom().primaryKey(),
    amount: integer('amount').notNull(),
    bonus: integer('bonus').notNull().default(0),
    createdAt: timestamp('createdAt', { mode: 'string' }).defaultNow(),
    userId: text('userId')
        .notNull()
        .references(() => users.id)
});

export const coinsTopupRelations = relations(coinsTopup, ({ one }) => ({
    user: one(users, {
        fields: [coinsTopup.userId],
        references: [users.id]
    })
}));

export const pinnedAdminPosts = pgTable('pinnedAdminPosts', {
    id: uuid('id').defaultRandom().primaryKey().notNull(),
    text: text('text').notNull(),
    imageUrl: text('imageUrl').notNull(),
    videoUrl: text('videoUrl').notNull(),
    thumbUrl: text('thumbUrl').notNull(),
    createdAt: timestamp('createdAt', { mode: 'string' }).defaultNow().notNull(),
    type: varchar('type').notNull(),
    active: boolean('active').default(true).notNull(),
    title: varchar('title').notNull(),
    link: varchar('link'),
    linkLabel: varchar('linkLabel')
});

export const settings = pgTable(
    'settings',
    {
        id: uuid('id').defaultRandom().primaryKey().notNull(),
        settingsKey: varchar('settingsKey').notNull(),
        settingsValue: text('settingsValue').notNull()
    },
    table => {
        return {
            settingsSettingsKeyUnique: unique('settings_settingsKey_unique').on(table.settingsKey)
        };
    }
);

export type CoinsTopup = typeof coinsTopup.$inferSelect;
export type AppSettings = typeof appSettings.$inferSelect;
export type PinnedAdminPost = typeof pinnedAdminPosts.$inferSelect;
export type Settings = typeof settings.$inferSelect;
